<template>
<v-container>
    <v-row>
      <v-col cols="12" md="8">
        
          <v-card
          outlined
          class="pa-4"
          >
          <v-card-title>Select Activity</v-card-title>
          <v-form
            ref="select_activity_form"
            lazy-validation
            >
            <v-row>
              <v-col cols="9" >
                <v-autocomplete
                  style="z-index: 120"
                  v-model="selected_activity"
                  :items="business_activity_list"
                  label="Select business activity"
                  item-text="name_en"
                  outlined
                  dense
                  item-value="object_id"
                  :error="error"
                :rules="selected_activity_rules"  
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-btn
                
                  color="teal white--text" 
                  v-on:click="get_data"
                  >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
		
	<div v-if="loading"  class="text-center">
		<v-row>
			<v-col cols="6">
				<v-progress-linear
					color="deep-purple accent-4"
					indeterminate
					rounded
					height="6"
				></v-progress-linear>
			</v-col>
		</v-row>
	</div>
	<div v-else>
		<div v-if="error">
			<v-row>
				<v-col cols="12" md="6">
					<v-alert border="left"
          color="blue"
          transition="slide-y-transition"
          type="info">
					{{error_message}}
					</v-alert>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-row>
				<v-col cols="12" md="6">
					<v-card
					outlined
					class="pa-2">
						<v-card-title>
							Activty Map
						</v-card-title>
						<div class="map" ref='map' ></div>
					</v-card>
				</v-col>

				<v-col cols="12" md="6">
					<v-card
					outlined
					class="pa-2">
						<v-card-title>
							Selected Branches
						</v-card-title>
					
							<v-data-table
							:headers="headers"
							:items="selected_branches"
							:items-per-page="10"
							:page.sync="page"
							
						>
							
						<template v-slot:item.cr_branch_number="{ item }">
							<router-link
								:to="{ path: 'branch/'+item.object_id }"
								>
							{{ item.cr_branch_number }}
						</router-link>
						
					</template>

						</v-data-table>
				</v-card>
				</v-col>
			</v-row>
		</div>
	</div>
</v-container>
</template>

<script>
// @ is an alias to /src
//https://blogs.bing.com/maps/May-2016-(1)/Pushpin-Clustering-in-Bing-Maps-V8
import business_json from '@/data/business_activity_list.json';
import {get_activity_map} from '@/api/app';
//import business_json from '@/data/business_activity_list.json'
export default {
  name: 'ActivityMap',
  data:function(){
    return {
      selected_activity_rules:[v => !!v || 'Activity is required',],

      headers:[
       { text: 'CR-Branch Number', value: 'cr_branch_number' ,sortable: false,align: 'center',width:110},
       { text: 'Name', value: 'name_en' ,sortable: false,},
      ],
      page: 1,
      pin_cluster:true,
      business_activity_list:business_json,
      error:false,
      error_message:'',
      loading:false,
      branches:[],
      pin_list:[],
      selected_branches:[],
      selected_activity:null,
      panel:[],
        
    }
 },
 methods:{
  fill_data: async function() {
    if (this.branches.length >0){
       let {
        Maps
      } = await this.getMap()
   

      //Maps.Location(26.208386, 50.538245)
      var navigationBarMode = Maps.NavigationBarMode;
      let map =new Maps.Map(this.$refs.map, {
        navigationBarMode: navigationBarMode.square,
        center: new Maps.Location(26.201660,50.523222),
        mapTypeId: Maps.MapTypeId.canvasLight,
        supportedMapTypes: [Maps.MapTypeId.road, Maps.MapTypeId.aerial, Maps.MapTypeId.grayscale,Maps.MapTypeId.canvasLight]

      })
      //console.log('map: ',map)
      // //https://www.bing.com/api/maps/sdk/mapcontrol/isdk/adddefaultpushpin#TS
      for(let i=0;i<this.branches.length;i++){
        let pin_location=new Maps.Location(this.branches[i].coordinate[0],this.branches[i].coordinate[1]);

        //console.log('object_id',this.branches[i].object_id);
        var pushpin = new Maps.Pushpin(pin_location, { color: 'rgba(39, 174, 96,8.0)'});
        //pushpin.setId(this.branches[i].object_id);
        pushpin.objectId=this.branches[i].object_id;
        let branch=this.branches[i];
        let _this=this;
        this.selected_branches=[];
        Maps.Events.addHandler(pushpin, 'click', function () { 
            //map.setView({ center: args.target.getLocation(), zoom: 15 }); 
            _this.selected_branches=[];
            _this.selected_branches.push(branch);
            console.log(branch.name_ar,branch.name_en);
            console.log('single push pin',pushpin);
        });

        this.pin_list.push(pushpin);
        if (this.pin_cluster==false){
          map.entities.push(pushpin);
        }
      }
      if (this.pin_cluster==true){
        console.log("Show icon cluster");
        var _this =this;
        var local_pin_list=this.pin_list;
        Maps.loadModule('Microsoft.Maps.Clustering', function () {
            var clusterLayer = new Maps.ClusterLayer(
              local_pin_list, 
              { 

                gridSize: 50,

                clusteredPinCallback:function(cluster){
                 var clusterSize = cluster.containedPushpins.length;
                   var minRadius = 12;

                var outlineWidth = 7;
                 var radius = Math.log(clusterSize) / Math.log(10) * 5 + minRadius;
                 var fillColor = 'rgba(18, 203, 196,0.5)';
                 if (clusterSize < 10) {
                 //Make the cluster green if there are less than 10 pushpins in it.
                fillColor = 'rgba(18, 137, 167,0.5)'; 
                } else if (clusterSize < 50) {
                //Make the cluster yellow if there are 10 to 99 pushpins in it.
                fillColor = 'rgba(6, 82, 221,0.5)';
                }else if (clusterSize < 100) {
                //Make the cluster yellow if there are 10 to 99 pushpins in it.
                fillColor = 'rgba(27, 20, 100,0.5)';
                }

                var svg = ['<svg xmlns="http://www.w3.org/2000/svg" width="', (radius * 2), '" height="', (radius * 2), '">',

                '<circle cx="', radius, '" cy="', radius, '" r="', radius, '" fill="', fillColor, '"/>',

                '<circle cx="', radius, '" cy="', radius, '" r="', radius - outlineWidth, '" fill="', fillColor, '"/>',

                '</svg>'];
                cluster.setOptions({

                  icon: svg.join(''),

                  anchor: new Maps.Point(radius, radius),

                textOffset: new Maps.Point(0, radius - 8) //Subtract 8 to compensate for height of text.

              });

                  //console.log("clusterpin clicked",cluster)
                  Maps.Events.addHandler(cluster, 'click', function(e){
                    console.log(e);
                    if (e.target.containedPushpins){
                      console.log("contain plushpin");
                      _this.selected_branches=[];
                      _this.page=1;
                      // tregger pins
                      for (let i=0;i<e.target.containedPushpins.length;i++){
                        // console.log('pin clicked',e.target.containedPushpins[i].objectId);
                        _this.add_branch_to_selected_branch(e.target.containedPushpins[i].objectId);
                      }
                    }
                  });
                } });
            map.layers.insert(clusterLayer);
          });
      }
    }
  },
  add_branch_to_selected_branch:function(object_id){
    for (let i=0;i<this.branches.length;i++){
      if (this.branches[i].object_id==object_id){
        this.selected_branches.push(this.branches[i]);
        break;
      }
    }
  },
  get_data:function(){
    if (this.$refs.select_activity_form.validate() ==false){
        return 
      }
    this.loading=true;
    // update data
    //let object_id =this.$route.params.objectId;
    //reset branches 
    this.branches=[];
    this.panel=[];
    const data_to_send={
           "object_id":this.selected_activity,
        }
    get_activity_map(data_to_send).then((response)=>{
          
          
          if (response.error ==false){
            this.branches=response.data;
            console.log('online data',response.data);
            if (this.branches.length==0){
				this.error=true;
				this.error_message="No branch found for the selected business activity";
				
			}else{
			this.error=false;
            this.fill_data();
            }

          }
          else{
            // show error 
            this.error=true;
            this.error_message=response.error_message;
          }
          this.loading=false;
        })
  },
   getMap() {
      
      let script = document.createElement('script')
      script.src = 'https://www.bing.com/api/maps/mapcontrol?key=AgzeobkGvmpdZTFuGa7_6gkaHH7CXHKsFiTQlBvi55x-QLZLh1rSjhd1Da9bfPhD&callback=getMap';
      document.head.append(script)
      return new Promise(resolve => {
        window.getMap = function() {
          resolve(window.Microsoft)
        }
      })
    },
},

   async mounted () {
    //console.log(this.$route.params.objectId);
  
    //this.get_data();

    },

    async activated(){
      //console.log("Activity keep a live");
      // reload the map
      this.fill_data();

    }
}
</script>

<style scoped>
.side_titles tr th{
  width: 180px;
}
.table_header_width{
  width: 180px; 
}

.map{
  width:100%;
  height: 585px;
}

.v-menu__content .theme--light .menuable__content__active .v-autocomplete__content{
  z-index: 200 !important;
}

@media only screen and (max-width: 768px) {
  .map{
    height: 450px;
  }
}

</style>