<template>
<v-container>
    <v-row>
      <v-col cols="12" md="8">
        
          <v-card
          outlined
          class="pa-4"
          >
          <v-card-title>Select Activity</v-card-title>
          <v-form
            ref="select_activity_form"
            lazy-validation
            >
            <v-row>
              <v-col cols="9" >
                <v-autocomplete
                  style="z-index: 120"
                  v-model="selected_activity"
                  :items="business_activity_list"
                  label="Select business activity"
                  item-text="name_en"
                  outlined
                  dense
                  item-value="object_id"
                  :error="error"
                :rules="selected_activity_rules"  
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-btn
                
                  color="teal white--text" 
                  v-on:click="get_data"
                  >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
		
	<div v-if="loading"  class="text-center">
		<v-row>
			<v-col cols="6">
				<v-progress-linear
					color="deep-purple accent-4"
					indeterminate
					rounded
					height="6"
				></v-progress-linear>
			</v-col>
		</v-row>
	</div>
	<div v-else>
		<div v-if="error">
			<v-row>
				<v-col cols="12" md="6">
					<v-alert border="left"
          color="blue"
          transition="slide-y-transition"
          type="info">
					{{error_message}}
					</v-alert>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-row>
				<v-col cols="12" md="6">
					<v-card
					outlined
					class="pa-2">
						<v-card-title>
							Activty Map
						</v-card-title>
						<div class="map" ref='map' ></div>
					</v-card>
				</v-col>

				<v-col cols="12" md="6">
					<v-card
					outlined
					class="pa-2">
						<v-card-title>
							Branch info
						</v-card-title>
					
						
				</v-card>
				</v-col>
			</v-row>
		</div>
	</div>
</v-container>
</template>

<script>
// @ is an alias to /src
//https://blogs.bing.com/maps/May-2016-(1)/Pushpin-Clustering-in-Bing-Maps-V8
import business_json from '@/data/business_activity_list.json';
import {get_activity_address_change} from '@/api/app';
//import business_json from '@/data/business_activity_list.json'
export default {
  name: 'ActivityMap',
  data:function(){
    return {
      selected_activity_rules:[v => !!v || 'Activity is required',],

      pin_cluster:false,
      business_activity_list:business_json,
      error:false,
      error_message:'',
      loading:false,
      branches:[],
      pin_list:[],
      selected_activity:null,
      panel:[],
        
    }
 },
 methods:{
  fill_data: async function() {
    if (this.branches.length >0){
       let {
        Maps
      } = await this.getMap()
   

      //Maps.Location(26.208386, 50.538245)
      let map =new Maps.Map(this.$refs.map, {
        center: new Maps.Location(26.201660,50.523222),

      })
      //console.log('map: ',map)
      // //https://www.bing.com/api/maps/sdk/mapcontrol/isdk/adddefaultpushpin#TS
      for(let i=0;i<this.branches.length;i++){
        let pin_location_from=new Maps.Location(this.branches[i].from.lat,this.branches[i].from.lon);
        let pin_location_to=new Maps.Location(this.branches[i].to.lat,this.branches[i].to.lon);

        //console.log('object_id',this.branches[i].object_id);
        var pushpin_from = new Maps.Pushpin(pin_location_from, { color: 'rgba(241, 196, 15,0.5)'});
        var pushpin_to = new Maps.Pushpin(pin_location_to, { color: 'rgba(52, 152, 219,0.5)'});
        //pushpin.setId(this.branches[i].object_id);
        // pushpin.objectId=this.branches[i].object_id;
        let branch=this.branches[i];
        let _this=this;
        this.selected_branches=[];
        var coords =[pin_location_from,pin_location_to];
        var line = new Maps.Polyline(coords, {
                  strokeColor: '#16a085',
                  strokeThickness: 2,
                  strokeDashArray: [1, 1]
              });
        map.entities.push(line)
        Maps.Events.addHandler(pushpin_from, 'click', function () {
            var coords =[pin_location_from,pin_location_to];
            var line = new Maps.Polyline(coords, {
                  strokeColor: '#16a085',
                  strokeThickness: 2,
                  strokeDashArray: [2, 4]
              });

            // remove other lines 
            for (let i=0;i<map.entities.getLength();i++){
              var polyline = map.entities.get(i);
                if (polyline instanceof Maps.Polyline) {
                    map.entities.removeAt(i);
                }
            }
            map.entities.push(line)
            // console.log("push line",);

            //map.setView({ center: args.target.getLocation(), zoom: 15 }); 
            _this.selected_branches=[];
            _this.selected_branches.push(branch);
            console.log(branch.name_ar,branch.name_en);
            console.log('single push pin',pushpin_from);
        });

       
        map.entities.push(pushpin_from);
        map.entities.push(pushpin_to);
        
      }
     
    }
  },
  add_branch_to_selected_branch:function(object_id){
    for (let i=0;i<this.branches.length;i++){
      if (this.branches[i].object_id==object_id){
        this.selected_branches.push(this.branches[i]);
        break;
      }
    }
  },
  get_data:function(){
    if (this.$refs.select_activity_form.validate() ==false){
        return 
      }
    this.loading=true;
    // update data
    //let object_id =this.$route.params.objectId;
    //reset branches 
    this.branches=[];
    this.panel=[];
    const data_to_send={
           "object_id":this.selected_activity,
        }
    get_activity_address_change(data_to_send).then((response)=>{
          
          
          if (response.error ==false){
            this.branches=response.data;
            console.log('online data',response.data);
            if (this.branches.length==0){
				this.error=true;
				this.error_message="No branch found for the selected business activity";
				
			}else{
			this.error=false;
            this.fill_data();
            }

          }
          else{
            // show error 
            this.error=true;
            this.error_message=response.error_message;
          }
          this.loading=false;
        })
  },
   getMap() {
      
      let script = document.createElement('script')
      script.src = 'https://www.bing.com/api/maps/mapcontrol?key=AgzeobkGvmpdZTFuGa7_6gkaHH7CXHKsFiTQlBvi55x-QLZLh1rSjhd1Da9bfPhD&callback=getMap';
      document.head.append(script)
      return new Promise(resolve => {
        window.getMap = function() {
          resolve(window.Microsoft)
        }
      })
    },
},

   async mounted () {
    //console.log(this.$route.params.objectId);
  
    //this.get_data();

    },

    async activated(){
      //console.log("Activity keep a live");
      // reload the map
      this.fill_data();

    }
}
</script>

<style scoped>
.side_titles tr th{
  width: 180px;
}
.table_header_width{
  width: 180px; 
}

.map{
  width:100%;
  height: 585px;
}

.v-menu__content .theme--light .menuable__content__active .v-autocomplete__content{
  z-index: 200 !important;
}

@media only screen and (max-width: 768px) {
  .map{
    height: 450px;
  }
}

</style>